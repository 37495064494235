@import '_vars';

/****************************
*   FOR RESPONSIVENESS      *
****************************/

::selection {
  background: @selection; /* WebKit/Blink Browsers */
	color: #fff;
}
::-moz-selection {
  background: @selection; /* Gecko Browsers */
	color: #fff;
}

html {
    font-size: 14px;
}
@media only screen and (min-width: 480px) {
    html {
        font-size: 17px;
    }
}
@media only screen and (min-width: 768px) {
    html {
        font-size: 14px;
    }
}
@media only screen and (min-width: 1024px) {
    html {
        //        font-size: 20px;
    }
    p {
        font-size: 1em;
        margin-bottom: 1.8em;
    }
}

/****************************
*      BODY DEFAULTS        *
****************************/

body {
    font-size: 1em;
//    font-family: @secondary-font;
//    background: @background;
}
a {
    color: @color1;
    &:hover {
        text-decoration: none;
        color: lighten(@color1, 10%);
    }
}


.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
//    font-family: @primary-font;
//    font-weight: bold;
}

/****************************
*      Buttons        *
****************************/

.btn{
	font-family: @primary-font;
	i{
		position: relative;
		top: -2px;
	}
}

.btn.text-left{
    text-align: left;
}

.btn-brand {
    background: @color1;
    color: #fff;
    &:hover {
        color: #fff;
        background: darken(@color1, 10%);
    }
}

.btn-dark {
    background: #272727;
    color: #fff;
    &:hover {
        color: #fff;
        background: darken(@color1, 10%);
    }
}

.btn-red {
    background: @selection;
    color: #fff;
    &:hover {
        color: #fff;
        background: darken(@selection, 10%);
    }
}

.btn-alt {
    background: #f0f0f0;
    color: #222;
    &:hover {
        color: #fff;
        background: darken(@color1, 10%);
    }
}

/****************************
*      Background Colors        *
****************************/

// bk because to prevent conflick from orifinal names
.bk-primary{
	background: @color-primary;
}
.bk-success{
	background: @color-success;
}
.bk-warning{
	background: @color-warning;
}
.bk-danger{
	background: @color-danger;
}
.bk-info{
	background: @color-info;
}

.bk-white,
.bk-light {
    background: #fff;
}
.bk-brand {
    background: @color1;
}
.bk-dark {
    background: #222;
}
.bk-blue {
    background: #0010ce;
}
.bk-alt {
    background: #f7f7f7;
}
.bk-ghost {
    background: transparent;
}
.bk-img {
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	background-image: url(../../img/login-bg.jpg);
}
.bk-fixed{
    background-attachment: fixed;
}

.hr-green{
	height: 2px;
	background: @color1;
}
.hr-grey{
	height: 2px;
	background: #f0f0f0;
}
.hr-dashed {
    background-color: transparent;
    border-top: 1px dotted #edf0f5;
    color: transparent;
    height: 1px;
    margin: 20px 0;
}

.panel{
	overflow: hidden;
}


/****************************
*      Border        *
****************************/
.brdr{
	border: 1px solid #f0f0f0;
}


/****************************
*      Text size color        *
****************************/

.font-one{
	font-family: @primary-font;
}

.text-bold {
    font-weight: bold;
}
.text-normal{
    font-weight: normal;
}
.text-thin{
    font-weight: 100;
}
.text-lowercase{
    text-transform: none;
}
.text-sm {
    font-size: 13px;
}
.text-lg {
    font-size: 18px;
}
.text-xl{
	font-size: 1.6em;
}
.text-2x{
	font-size: 2em;
}
.text-3x{
	font-size: 2.4em;
}
.text-4x{
	font-size: 3em;
}
.text-white,
.text-light {
    color: #fff !important;
}

.link-white a{
	color: #fff;
}

.text-grey{
    color: #ccc;
}

.text-dgrey{
    color: #818181;
}

.text-brand {
    color: @color1;
}
.text-yellow {
    color: #ffff00;
}

.title{
	.line{
		display: inline-block;
		padding-bottom: 10px;
		border-bottom: 3px solid #222;
	}
}

/****************************
*       Margins         *
****************************/
.mt {
    margin-top: 18px;
    &-0x{
        margin-top: 0 !important;
    }
    &-2x{
        margin-top: 36px;
    }
    &-3x{
        margin-top: 54px;
    }
    &-4x{
        margin-top: 90px;
    }
    &-5x{
        margin-top: 144px;
    }
}
.mb {
    margin-bottom: 18px;
	&-0x{
        margin-bottom: 0 !important;
    }
    &-2x{
        margin-bottom: 36px;
    }
    &-3x{
        margin-bottom: 54px;
    }
    &-4x{
        margin-bottom: 90px;
    }
    &-5x{
        margin-bottom: 144px;
    }
}
.pt{
    padding-top: 18px;
    &-2x{
        padding-top: 36px;
    }
    &-3x{
        padding-top: 54px;
    }
    &-4x{
        padding-top: 90px;
    }
    &-5x{
        padding-top: 144px;
    }
}
.pb{
    padding-bottom: 18px;
    &-2x{
        padding-bottom: 36px;
    }
    &-3x{
        padding-bottom: 54px;
    }
    &-4x{
        padding-bottom: 90px;
    }
    &-5x{
        padding-bottom: 144px;
    }
}


/* Content boxes */
.content-box{
	padding: 15px;
}

.block-anchor{
	display: block;
}

/****************************
*       Content Typography         *
****************************/
.content-format{
    line-height: 1.6em;
    font-weight: 400;
    letter-spacing: .01em;
    font-style: normal;
    word-wrap: break-word;
    .h1,.h2,.h3,.h4,.h5,.h6,
    h1,h2,h3,h4,h5,h6{
        font-family: @primary-font;
        font-weight: 700;
        font-weight: bold;
        font-style: normal;
        color: rgba(0,0,0,0.8);
        margin-top: 40px;
        a{
            font-size: inherit;
        }
    }
    .h2,
    h2{
      font-size: 3em;
      line-height: 1;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 4px solid #f0f0f0;
    }
    .h3,
    h3{
      font-size: 2em;
      line-height: 1.2;
      margin-bottom: 4px;
      margin: 1em 0 0.5em;
    }
    .h4,
    h4{
      font-size: 1.7em;
      line-height: 1.4;
      margin-bottom: 6px;
    }
    .h5,
    h5{
      font-size: 1.5em;
      line-height: 1.4;
      margin-bottom: 8px;
    }
    .h6,
    h6{
      font-size: 1.3em;
      line-height: 1.4;
      margin-bottom: 10px;
    }
    strong{
        font-weight: bold;
    }
    ul:not(.social-awesome){
        list-style: disc outside;
        margin: 1.714285714rem 0 1.714285714rem;
        line-height: 1.714285714;
        li{
              margin: 0 0 0 36px;
              margin: 0 1rem 0 2.571428571rem;
        }
        &.list-unstyled{
            list-style: none;
            li{
                margin: 0 0 0 24px;
            }
        }
    }
    em{
        font-style: italic;
    }
    p{
        margin-bottom: 1.8em;
    }
    figure{
        .caption{            
            color: #999;
            font-size: 14px;
        }
        margin-bottom: 42px;
    }
    img{
        border: 0;
        height: auto;
        max-width: 100%;
        vertical-align: middle;
    }
    .fa{
        color: @color1;
        margin-right: 10px;
    }
    blockquote {
      border-left: 4px solid #c3c3c3;
      border-left: 4px solid rgba(51, 51, 51, 0.17);
      color: #707070;
      color: rgba(51, 51, 51, 0.7);
      font-size: 1.2em;
      font-style: italic;
      line-height: 1.6667;
      margin-bottom: 1em;
      padding-left: 0.7778em;
      &.double-quote{
          border-left: none;
          padding-left: 1em;
      }
    }
    pre{
          background: #fff;
          border: 1px solid #ededed;
          color: #666;
          font-family: Consolas, Monaco, Lucida Console, monospace;
          font-size: .9em;
          line-height: 1.714285714;
          margin: 24px 0;
          margin: 1.714285714rem 0;
          overflow: auto;
    }
    ol{
          margin: 24px 0 24px;
          margin: 1.714285714rem 0 1.714285714rem;
          line-height: 1.714285714;
          list-style: decimal outside;
    }
    img.wp-smiley, img.emoji {
          display: inline !important;
          border: none !important;
          box-shadow: none !important;
          height: 1em !important;
          width: 1em !important;
          margin: 0 .07em !important;
          vertical-align: -0.1em !important;
          background: none !important;
          padding: 0 !important;
    }
}